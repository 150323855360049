<template>
  <div class="screen">
    <section class="content">
      <ul class="news">
        <li class="item" v-for="(A,a) in news" :key="a" @click="nav(A.link)">
          <img class="cover" :src="A.cover" :alt="A.title">
          <span class="title">{{A.title}}</span>
          <span class="time">{{A.date}}</span>
        </li>
      </ul>
      <page-footer class="footer" />
    </section>
  </div>
</template>

<script>
import pageFooter from '@/components/Footer'

export default {
  name: "screen5",
  components: {
    pageFooter,
  },
  data() {
    return {
      news: []
    }
  },
  mounted() {
    fetch('/news.json')
      .then(res => { return res.json() })
      .then(res => {
        this.news = res.data
      })
  },
  methods: {
    nav(link) {
      window.open(link, '')
    }
  }
}
</script>

<style lang="scss" scoped>
.screen {
  height: 100%;
  padding-top: 100px;
  box-sizing: border-box;
  background: linear-gradient(-45deg, #517fa4, #243949);
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .news {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;

    .item {
      list-style: none;
      width: 430px;
      background-color: rgba(#000, 0.2);
      display: flex;
      flex-direction: column;
      padding: 0 15px 24px;
      box-sizing: border-box;
      transition: transform .3s;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 65px;
      }
      &:hover {
        transform: scale(1.02);
      }

      .cover {
        width: calc(100% + 30px);
        margin-left: -15px;
        height: 200px;
        object-fit: cover;
        display: block;
        background-color: #fff;
      }
      .title {
        font-size: 18px;
        color: #fff;
        line-height: 1;
        margin-top: 24px;
      }
      .time {
        font-size: 16px;
        color: rgba(#fff, 0.6);
        line-height: 1;
        margin-top: 24px;
      }
    }
  }
}
</style>
