<template>
  <div class="screen">
    <div class="container">
      <section class="title">
        <h1>{{ title }}</h1>
        <!-- <h6>({{ subtitle }})</h6> -->
      </section>

      <section class="content">
        <p class="intro" v-html="intro"></p>

        <div class="link-group">
          <router-link class="link" v-for="(A,a) in links" :to="A.path" :key="a">
            {{A.title}}
            <i class="iconfont icon-you"></i>
          </router-link>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "screen3",
  data() {
    return {
      title: '梦境世界',
      subtitle: '品牌打造',

      intro: '梦境VR将不断探索创新，拓宽游戏的边界，发展更多创新玩法，<br>通过游戏与前沿VR技术的融合，为玩家提供更美妙的游戏体验和乐趣，<br>推动VR游戏行业的发展，发现更多好玩的VR游戏，<br>进入梦境VR的国度，创造属于你的世界！',
      links: [
        {
          title: '梦境VR社交',
          path: '/mengjing'
        }, {
          title: '梦境VR内容云平台',
          path: '/mengjing'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.screen {
  height: 100%;
  padding: 100px 0 0 120px;
  box-sizing: border-box;
  background: linear-gradient(to right, #ff7e5f, #feb47b);
}

.container {
  max-width: 1920px;
  height: 100%;
  margin: 0 auto;
  padding-left: 120px;
  //box-sizing: border-box;
  background-image: url("~@/assets/human_2@2x.png");
  background-position: right bottom;
  // background-size: auto max(calc(980px * 0.75), 60%);
  background-size: auto 90%;
  background-repeat: no-repeat;
}

.title {
  position: absolute;
  margin-top: 108px;
  left: 120px;
  display: inline-block;

  h1,
  h6 {
    margin: 0;
    color: #fff;
    text-align: center;
    font-weight: lighter;
  }

  h1 {
    margin-bottom: 20px;
    letter-spacing: 10px;
    font-size: 60px;
  }

  h6 {
    text-align: center;
    font-size: 20px;
    letter-spacing: 5px;
  }
}

.content {
  position: absolute;
  margin-top: 400px;
  left: 120px;
  top: 0;

  .intro {
    font-size: 20px;
    color: rgba(#fff, 0.6);
    line-height: 48px;
    margin: 0 0 80px 0;
  }

  .link-group {
    .link {
      font-size: 30px;
      color: rgba(#fff, 0.6);
      text-decoration: none;
      margin-right: 80px;
      transition: color 0.2s;
      .iconfont {
        font-size: 24px;
      }

      &:hover {
        color: rgba(#fff, 1);
      }
    }
  }
}
</style>
