<template>
  <div class="screen">
    <div class="bg-video">
      <div id="video"></div>
      <div class="video-mask"></div>
    </div>
    <div class="wrapper">
      <h1 class="title">无拘无束</h1>
      <h2 class="subtitle">探索令人惊叹的游戏和体验</h2>
      <!--<div class="tip-scroll">
        <i class="iconfont icon-mouse"></i>
      </div>-->
      <div class="mouse">
        <div class="wheel"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Chimee from 'chimee'
import hls from 'chimee-kernel-hls'
let chimee

export default {
  name: "screen1",
  methods: {
    /** 视频播放暂停 */
    handleChangeVideoStatus(status) {
      // status === 'play' && this.$refs.video.play()
      // status === 'pause' && this.$refs.video.pause()
      status === 'play' && chimee.play()
      status === 'pause' && chimee.pause()
    },
  },
  mounted() {
    chimee = new Chimee({
      wrapper: '#video',
      src: 'http://hwcdn.zmvr.com/videohost/vrhome_home/video.m3u8',
      controls: false,
      autoplay: true,
      muted: true,
      loop: true,
      kernels: {
        hls
      }
    })
    chimee.play()
  }
}
</script>

<style lang="scss" scoped>
.screen {
  height: 100%;
  background: linear-gradient(to right, #ff7e5f, #feb47b);
  position: relative;
}

.bg-video,
.video-mask,
.wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.bg-video {
  #video {
    width: 100%;
    height: 100%;

    ::v-deep video {
      object-fit: cover;
    }
  }

  .video-mask {
    background-color: rgba($color: #000, $alpha: 0.5);
    // backdrop-filter: blur(10px);
  }
}
video:focus,
video:active {
  outline: none;
}

.wrapper {
  padding-top: 100px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: relative;

  h1,
  h2 {
    margin: 0;
    font-weight: lighter;
    font-family: "PingFangLight";
  }

  .title {
    font-size: 100px;
    margin-bottom: 60px;
    letter-spacing: 40px;
  }

  .subtitle {
    font-size: 56px;
    letter-spacing: 10px;
    margin-bottom: 150px;
  }

  .tip-scroll {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, -60px);

    .icon-mouse {
      font-size: 24px;
      color: rgba($color: #fff, $alpha: 0.5);
    }
  }

  .mouse {
    width: 18px;
    height: 30px;
    border-radius: 60px;
    border: solid 2px #fff;
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, -60px);

    .wheel {
      width: 3px;
      height: 5px;
      border-radius: 3px;
      background-color: #fff;
      position: absolute;
      left: 50%;
      top: 7px;
      transform: translateX(-50%);
      animation-name: ani-wheel;
      animation-duration: 1000ms;
      animation-timing-function: ease-out;
      animation-iteration-count: infinite;
    }
  }
}

@keyframes ani-wheel {
  to {
    top: 3px;
    background-color: rgba($color: #fff, $alpha: 0.1);
  }
}
</style>
