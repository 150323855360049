<template>
  <div class="screen">
    <div class="container">
      <section class="title">
        <h1>{{ title }}</h1>
        <!-- <h6>({{ subtitle }})</h6> -->
      </section>

      <section class="content">
        <p class="intro" v-html="intro"></p>

        <div class="link-group">
          <router-link class="link" v-for="(A,a) in links" :to="A.path" :key="a">
            {{A.title}}
            <i class="iconfont icon-you"></i>
          </router-link>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "screen4",
  data() {
    return {
      title: '关于造梦',
      subtitle: '加入我们',

      intro: '造梦科技一直以来的追求与梦想是创造一个梦幻的虚拟世界，<br>目前，造梦多个项目正在高速发展中，涉及VR虚拟现实的多个领域，<br>包含C端5G云内容社交平台，B端内容分发平台，线下VR虚拟现实招商合作项目，<br>线下VR虚拟现实直营场馆，大空间VR娱乐方案等等。',
      links: [
        {
          title: '公司简介',
          path: '/about'
        }, {
          title: '发展历程',
          path: '/about'
        }, {
          title: '商业合作',
          path: '/about'
        }, {
          title: '岗位招聘',
          path: '/about'
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.screen {
  height: 100%;
  padding-top: 100px;
  box-sizing: border-box;
  background: linear-gradient(-45deg, #084c90, #0edee3);
}

.container {
  max-width: 1920px;
  height: 100%;
  margin: 0 auto;
  padding-left: 120px;
  //box-sizing: border-box;
  background-image: url("~@/assets/human_3@2x.png");
  background-position: right bottom;
  // background-size: auto max(calc(980px * 0.75), 60%);
  background-size: auto 90%;
  background-repeat: no-repeat;
}

.title {
  position: absolute;
  margin-top: 108px;
  left: 120px;
  display: inline-block;

  h1,
  h6 {
    margin: 0;
    color: #fff;
    text-align: center;
    font-weight: lighter;
  }

  h1 {
    margin-bottom: 20px;
    letter-spacing: 10px;
    font-size: 60px;
  }

  h6 {
    text-align: center;
    font-size: 20px;
    letter-spacing: 5px;
  }
}

.content {
  position: absolute;
  margin-top: 400px;
  left: 120px;
  top: 0;

  .intro {
    font-size: 20px;
    color: rgba(#fff, 0.6);
    line-height: 48px;
    margin: 0 0 80px 0;
  }

  .link-group {
    .link {
      font-size: 30px;
      color: rgba(#fff, 0.6);
      text-decoration: none;
      margin-right: 80px;
      transition: color 0.2s;
      .iconfont {
        font-size: 24px;
      }

      &:hover {
        color: rgba(#fff, 1);
      }
    }
  }
}
</style>
