<template>
  <div class="page-home">
    <swiper class="content-wrapper swiper-no-swiping" ref="content" :options="swiperOptions">
      <swiper-slide>
        <screen1 ref="screen1" />
      </swiper-slide>
      <swiper-slide>
        <screen2 @fnDisable="handelDisable" @fnEnable="handelEnable" />
      </swiper-slide>
      <swiper-slide>
        <screen3 />
      </swiper-slide>
      <swiper-slide>
        <screen4 />
      </swiper-slide>
      <swiper-slide>
        <screen5 />
      </swiper-slide>

      <div class="swiper-pagination--home" :class="{hasPadingBottom: currentSwiper===4}" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
let that

import { screen1, screen2, screen3, screen4, screen5 } from './content'

export default {
  components: {
    screen1,
    screen2,
    screen3,
    screen4,
    screen5
  },
  data() {
    return {
      currentSwiper: 0,
      swiperOptions: {
        direction: 'vertical',
        slidesPerView: 1,
        mousewheel: true,
        effect: 'fade',
        thresholdDelta: 100,
        pagination: {
          el: '.swiper-pagination--home',
          clickable: true,
        },
        keyboard: {
          enabled: true,
          onlyInViewport: true,
        },
        on: {
          slideChangeTransitionStart: function () {
            that.currentSwiper = this.activeIndex
          },
        }
      }
    }
  },
  computed: {
    swiper() {
      return this.$refs.content && this.$refs.content.$swiper
    }
  },
  mounted() {
    that = this
    this.$refs.screen1.handleChangeVideoStatus('play')
  },
  watch: {
    currentSwiper(i) {
      // 首屏视频播放暂停
      this.$refs.screen1.handleChangeVideoStatus(i === 0 ? 'play' : 'pause')
      // 导航啦交互
      this.$emit('transNavBar', i === 0 ? {
        show: false,
        open: true
      } : {
        show: true,
          open: false
        })
    }
  },
  methods: {
    handelDisable() {
      this.swiper.mousewheel.disable()
    },
    handelEnable() {
      this.swiper.mousewheel.enable()
    },
  }
}
</script>

<style lang="scss" scoped>
.content-wrapper {
  height: 100vh;
}
::v-deep .swiper-pagination--home {
  //right: min(calc((100vw - 1680px) / 2 + 10px), 110px);
  height: 100vh;
  position: absolute;
  right: 110px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
  padding-bottom: unset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  transition: padding-bottom 0.3s;

  &.hasPadingBottom {
    padding-bottom: 250px;
  }

  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background-color: #fff;
    opacity: 1;
    margin: 20px 0;
    position: relative;
    outline: none;


    &:before {
      content: "";
      width: calc(100% + 10px);
      height: calc(100% + 10px);
      border-radius: 50%;
      border: solid 2px #fff;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.3s;
    }

    &-active {
      &:before {
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }
}

::v-deep .footer {
  margin-top: 100px;
}
</style>
