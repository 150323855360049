<template>
  <div class="screen">
    <div class="container">
      <section class="title">
        <h1>{{ title }}</h1>
        <!-- <h6>({{ subtitle }})</h6> -->
      </section>

      <section class="content">
        <p class="intro" v-html="intro"></p>

        <div class="link-group">
          <router-link class="link" v-for="(A,a) in links" :to="A.path" :key="a">
            {{A.title}}
            <i class="iconfont icon-you"></i>
          </router-link>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "screen3",
  data() {
    return {
      title: "造梦VR",
      subtitle: "热门大作",

      intro:
        "ZMVR造梦内容平台<br>—— 千款精品内容，全国体验馆都在用<br>Quest无线大空间方案<br>—— 高品质，真无线，零负重，超低价",
      links: [
        {
          title: "ZMVR造梦内容平台",
          path: "/zaomeng",
        },
        {
          title: "Quest无线大空间方案",
          path: "/zaomeng",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.screen {
  height: 100%;
  padding: 100px 0 0 120px;
  box-sizing: border-box;
  background: linear-gradient(to right, #6190e8, #a7bfe8);
}

.container {
  max-width: 1920px;
  height: 100%;
  margin: 0 auto;
  padding-left: 120px;
  //box-sizing: border-box;
  background-image: url("~@/assets/human_1@2x.png");
  background-position: right bottom;
  // background-size: auto max(calc(980px * 0.75), 60%);
  background-size: auto 90%;
  background-repeat: no-repeat;
}

.title {
  position: absolute;
  margin-top: 108px;
  left: 120px;
  display: inline-block;

  h1,
  h6 {
    margin: 0;
    color: #fff;
    text-align: center;
    font-weight: lighter;
  }

  h1 {
    margin-bottom: 20px;
    letter-spacing: 10px;
    font-size: 60px;
  }

  h6 {
    text-align: center;
    font-size: 20px;
    letter-spacing: 5px;
  }
}

.content {
  position: absolute;
  margin-top: 400px;
  left: 120px;
  top: 0;

  .intro {
    font-size: 20px;
    color: rgba(#fff, 0.6);
    line-height: 48px;
    margin: 0 0 80px 0;
  }

  .link-group {
    .link {
      font-size: 30px;
      color: rgba(#fff, 0.6);
      text-decoration: none;
      margin-right: 80px;
      transition: color 0.2s;
      .iconfont {
        font-size: 24px;
      }

      &:hover {
        color: rgba(#fff, 1);
      }
    }
  }
}
</style>
